








































































import { LotConfig, NftMetadata, DepositInfo } from '@/type';
import { Component, Prop, Vue, PropSync, VModel, Emit } from 'vue-property-decorator';
import { convertValueFromContract, countdown, getIpfsPath } from '@/utils/utilities';
import TcoinInfoBox from '@/components/Common/TcoinInfoBox.vue'

  @Component({
      components: {
        TcoinInfoBox
      }
  })
  export default class DepositBank extends Vue {
    @VModel()
    dialog!: boolean

    @Prop()
    lotConfig!: LotConfig

    @Prop()
    lotData!: number

    @Prop()
    loading!: boolean

    @Prop()
    tcoinBalance!: number

    @PropSync('amount')
    selectedAmount!: number
    

    @Emit('close')
    closeDialog () {
        return
    }


    @Emit('deposit')
    deposit () {
        return
    }

    errorMessageDeposit () {
      const errorMessage = []
      if (this.lotData - this.selectedAmount < 0) {
        errorMessage.push(`Only ${this.lotData} available space!`)
      }

      if (!Number.isInteger(Number(this.selectedAmount))) {
        errorMessage.push(`Amount should be an integer!`)
      }

      return errorMessage
    }


    isDisabledDeposit () {
      if (this.errorMessageDeposit().length) {
        return true
      }

      if (!this.selectedAmount) {
        return true
      }

      return false
    }

    get daysPeriod () {
      const ONE_DAY = 60 * 60 * 24
      return this.lotConfig.time_per_period / ONE_DAY
    }

    get availablePlaceText () {
      return `Available space : ${ this.lotData }/${ this.lotConfig.nb_reward }`
    }

    get toStack () {
      if (!this.selectedAmount) {
        return 0
      }
      return this.tcoinRequired * this.selectedAmount
    }


    get tcoinRequired () {
      return convertValueFromContract(this.lotConfig.tcoin_required)
    }

  }
