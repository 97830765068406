


































import { Component, Prop, Vue, PropSync, VModel, Watch } from 'vue-property-decorator';
import LotCard from './LotCard.vue'
import InfoBank from './InfoBank.vue'
import {getAllTokenMetadata, getContractStorage} from '@/apiCaller/tzktApi';
import {DepositInfo, LotConfig, NftMetadata, StorageBank} from '@/type';
import { mixins } from 'vue-class-component';
import HasTcoinBalance from '@/mixins/HasTcoinBalance';
import { getBigMapsOfContract, getBigMap, getBlockCount, getGetInBigMap, getContractStorage as getStorageTzkt } from '../../apiCaller/tzktApi'

  @Component({
      components: {
        LotCard,
        InfoBank
      }
  })
  export default class TrooperzBank extends mixins(HasTcoinBalance) {
    storageBank: StorageBank | null = null

    loading = false

    hasFreeSpace = false

    stylesBank = {
        background: 'url(' + require('@/assets/bank/bank_background.png') + ')'
    }

    allTokenMetadata: Record<string, NftMetadata> = {}

    async mounted () {
      await this.getData()
    }

    async getData () {
      this.loading = true
      await this.getLotData()
      let allDeposits: Record<string, DepositInfo[]> = {}
      if (this.storageBank) {
        for (let key in this.storageBank.lot_data) {
          const res = await this.getDeposit(key)
          if (res) {
            allDeposits[key] = res
          }
        }
      }
      this.$store.commit('setDepositInfos', allDeposits)
      await this.getConsumablesMetadata()
      this.loading = false
    }

    async getLotData() {
        const config = this.$config
        if (config) {
            const storage = await getContractStorage<StorageBank>(config.networkTzkt, config.contractBank)

            if (storage) {
              this.storageBank = storage
            }
        }

    }

    async getDeposit (lotId: string) {
      if (!this.$config) {
        console.warn('No Config!')
        return
      }
      let depositInfo: DepositInfo[] = []
      const activeAccount = await this.$wallet.client.getActiveAccount();
      if (!activeAccount) {
        console.warn('No active account!')
        return
      }
      const keys = `{"address":"${activeAccount.address}","lot_id":"${lotId}"}`
      const result = await getGetInBigMap<Record<number, DepositInfo>>(this.$config.networkTzkt, this.$config.contractBank, 'ledger', keys)
      if (result.active) {
        depositInfo = Object.values(result.value)
      }
      return depositInfo
    }

    getTokenMetadata (lotConfig: LotConfig) {
      if (!this.allTokenMetadata) {
        return []
      }
      const tokenId = lotConfig.rewards.token_id
      if (this.allTokenMetadata[tokenId]) {
        return [this.allTokenMetadata[tokenId]]
      }
      return []
    }

    async getConsumablesMetadata () {
      if (!this.$config) {
        console.warn('No Config!')
        return
      }
      const result = await getAllTokenMetadata(this.$config?.networkTzkt, this.$config?.contractBooster)
      if (result) {
        for (let item of result) {
          this.allTokenMetadata[item.tokenId] = item.metadata
        }
      }
    }

    get depositInfos () {
      return this.$store.getters.getDepositInfos
    }

    get textFreeSpace () {
      return this.hasFreeSpace ? 'Lots with space' : 'All lots'
    }

    get filteredLot () {
      if (!this.storageBank) {
        return []
      }

      if (!this.hasFreeSpace) {
        return this.storageBank.lot
      }
      let lots: Record<string, LotConfig> = {}

      for (let key in this.storageBank.lot) {
        if (this.storageBank.lot_data[key] > 0) {
          lots[key] = this.storageBank.lot[key]
        }
      }

      return lots
    }

    @Watch('$store.getters.getAddress', {deep: true})
    onChangeAddress (address?: string) {
      if (address) {
        this.getData()
      } else {
        this.$store.commit('setDepositInfos', {})
      }
    }
  }
  