



































import { LotConfig, NftMetadata, DepositInfo, StorageDexQuipu } from '@/type';
import { Component, Prop, Vue, PropSync, VModel, Emit } from 'vue-property-decorator';
import { convertValueFromContract, countdown, getIpfsPath, roundValue } from '@/utils/utilities';
import { getTokenBalanceOfUser, getTokenInfo, getContractStorage } from '@/apiCaller/tzktApi';

  @Component({
      components: {
        
      }
  })
  export default class InfoBank extends Vue {
    @Prop()
    depositInfos!: Record<string, DepositInfo[]>

    @Prop()
    lotConfig!: Record<string, LotConfig>

    burnedTcoin = 0

    burnAddress = 'tz1burnburnburnburnburnburnburjAYjjX'

    tokenInfo: {totalSupply: number, totalMinted: number} = {totalSupply: 0, totalMinted: 0}

    dexStorage: StorageDexQuipu = {storage: {tez_pool: '0', token_pool: '0'}}

    totalInStacking = 0

    mounted () {
        this.getInfoToken()
        this.getBurnedTcoin()
        this.getDexInfo()
        this.getTotalInStacking()
    }

    async getBurnedTcoin () {
        const config = this.$config
        if (!config) {
            console.warn('No config!')
            return
        }
        const response = await getTokenBalanceOfUser(this.burnAddress, config.networkTzkt, config.tcoinAddress)
        if (response) {
            if (response.data.length) {
                this.burnedTcoin = convertValueFromContract(Number(response.data[0].balance), config.nbDigitTcoin) 
            } else {
                this.burnedTcoin = 0
            }
        } else {
            console.warn(`Response error : `, response)
            this.burnedTcoin = 0
        }
    }

    async getTotalInStacking () {
        const config = this.$config
        if (!config) {
            console.warn('No config!')
            return
        }
        const response = await getTokenBalanceOfUser(config.contractBank, config.networkTzkt, config.tcoinAddress)
        if (response) {
            if (response.data.length) {
                this.totalInStacking = convertValueFromContract(Number(response.data[0].balance), config.nbDigitTcoin) 
            } else {
                this.totalInStacking = 0
            }
        } else {
            console.warn(`Response error : `, response)
            this.totalInStacking = 0
        }
    }

    async getInfoToken () {
        const config = this.$config
        if (!config) {
            console.warn('No config!')
            return
        }
        const response = await getTokenInfo(config.networkTzkt, config.tcoinAddress, 0)
        if (response) {
            this.tokenInfo = {
                totalSupply: Number(response[0].totalSupply),
                totalMinted: Number(response[0].totalMinted)
            }
        }
    }

    async getDexInfo () {
        const dexAddr = 'KT1LepCZGCagf8ZnRtpHa9RBqCkqx1QCBNmn'
        const config = this.$config
        if (!config) {
            console.warn('No config!')
            return
        }
        const response = await getContractStorage<StorageDexQuipu>('mainnet', dexAddr)
        if (response) {
            this.dexStorage = response
        }
    }

    get totalStacked () {
        if (!this.depositInfos) {
            return 0
        }
        let total = 0
        for (let key in this.depositInfos) {
            const deposits = this.depositInfos[key]
            const amount = deposits.reduce((prev, curr) => {
                return prev + Number(curr.amount)
            }, 0) 
            const res = convertValueFromContract(this.lotConfig[key].tcoin_required * amount)
            total += res
        }
        return total
    }

    get tcoinBalance () {
        return this.$store.getters.getTcoinBalance
    }

    get totalSupply () {
        return convertValueFromContract(this.tokenInfo.totalSupply) - this.burnedTcoin
    }

    get tcoinPrice () {
        const tezAmount = convertValueFromContract(Number(this.dexStorage.storage.token_pool))
        const tcoinAmount = convertValueFromContract(Number(this.dexStorage.storage.tez_pool), 6)
        return roundValue(tcoinAmount / tezAmount, 4)
    }
  }
