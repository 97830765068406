






























































































































































import { LotConfig, NftMetadata, DepositInfo } from '@/type';
import { convertValueFromContract, countdown, getIpfsPath } from '@/utils/utilities';
import { Component, Prop, Vue, PropSync, VModel, Emit, Watch } from 'vue-property-decorator';
import { getTokenMetadata, getGetInBigMap } from '@/apiCaller/tzktApi';
import { bankDeposit, bankHarvest } from '@/contractCaller/contractCaller';
import OperationLoadingDialog from '@/components/OperationLoadingDialog.vue'
import DepositBank from '@/components/Bank/DepositBank.vue'
import BaseDialog from '../Common/BaseDialog.vue';
import HasWarning from '@/mixins/HasWarning';
import { mixins } from 'vue-class-component';

  @Component({
      components: {
        OperationLoadingDialog,
        DepositBank,
        BaseDialog
      }
  })
  export default class LotCard extends mixins(HasWarning) {
    @Prop()
    lotConfig!: LotConfig

    @Prop()
    lotData!: number

    @Prop()
    lotId!: number

    @Prop()
    tcoinBalance!: number

    @Prop()
    depositInfo!: DepositInfo[]

    @Prop()
    tokenMetadata!: NftMetadata[]

    selectedAmount = 1

    activeDialog = false

    activeOperationDialog = false

    loading = false

    success = false

    opeHash: string | null = null

    countdownDeposit: string[] = []

    textNoSpace = `
    There is no more space in this lot!
    `

    showDeposit = false

    mounted () {
      this.getData()
    }

    getData () {
      this.setCountdownDeposit()
    }

    onSuccess () {
      this.closeDialog()
      this.getData()
      this.emitRefresh()
    }

    @Emit('refresh')
    emitRefresh () {
      return
    }

    setCountdownDeposit () {
      window.setInterval(() => {
          for (let i in this.depositInfo) {
            this.$set(this.countdownDeposit, i, this.displayCountdown(this.depositInfo[i].date))
          }
        }, 1000)
    }

    displayCountdown (depositDate: string) {
      let distance = this.getDistanceDeposit(depositDate)
      if (distance < 0) {
        distance = 0
      }
      return countdown(distance)
    }

    openWarningAndDeposit () {
      const text = `You will deposit ${this.selectedAmount * this.tcoinRequired} TCOIN for ${this.daysPeriod} days,
      and receive ${this.rewardsSentence(this.selectedAmount)} at the end of the period.
      You will be unable to withdraw before the end of the period.`
      this.openWarning(text, this.deposit, null, "Ok, let's go!", "Cancel")
    }

    getDistanceDeposit (depositDate: string) {
      const startDate = new Date(depositDate)
      const endDate = startDate.getTime() + (Number(this.lotConfig.time_per_period) * 1000)
      const now = Date.now()
      return endDate - now
    }

    openDepositDialog () {
      this.selectedAmount = 1
      this.activeDialog = true
    }

    closeDialog () {
      this.activeDialog = false
    }

    getIpfsPath (ipfs: string) {
        return getIpfsPath(ipfs)
    }

    getStackedTcoin (amount: number) {
      return convertValueFromContract(this.lotConfig.tcoin_required * amount)
    }

    async deposit () {
      if (!this.$config) {
        console.warn('No Config!')
        return
      }
      this.loading = true
      this.success = false
      this.opeHash = null
      this.activeOperationDialog = true

      const contractBankAddress = this.$config.contractBank
      const contractTcoinAddress = this.$config.tcoinAddress

      let error = false

      try {
        const batch = await bankDeposit(this.$tezos.wallet, contractBankAddress,
          contractTcoinAddress, this.lotId, this.selectedAmount, this.lotConfig)
        this.opeHash = batch.opHash
        const result = await batch.confirmation();
      } catch (e) {
        console.warn(e)
        error = true
        this.activeOperationDialog = false
      }
      this.success = !error
      this.loading = false
    }

    async harvest () {
      if (!this.$config) {
        console.warn('No Config!')
        return
      }
      this.loading = true
      this.success = false
      this.opeHash = null
      this.activeOperationDialog = true

      const contractBankAddress = this.$config.contractBank

      let error = false
      try {
        const batch = await bankHarvest(this.$tezos.wallet, contractBankAddress, this.lotId)
        this.opeHash = batch.opHash
        const result = await batch.confirmation();
      } catch (e) {
        console.warn(e)
        error = true
        this.activeOperationDialog = false
      }
      this.success = !error
      this.loading = false
    }


    isDisabledHarvest () {
      for (let info of this.depositInfo) {
        if (this.getDistanceDeposit(info.date) <= 0) {
          return false
        }
      }
      return true
    }

    labelBtn (label: string) {
      return this.currentAddress ? label : 'Connect wallet'
    }


    get availablePlaceText () {
      return `Available space : ${ this.lotData }/${ this.lotConfig.nb_reward }`
    }

    get hasSpace () {
      return this.lotData > 0
    }

    get toStack () {
      if (!this.selectedAmount) {
        return 0
      }
      return this.tcoinRequired * this.selectedAmount
    }

    rewardsSentence (amount: number) {
      if (!this.lotConfig || !this.lotConfig.rewards || !this.tokenMetadata || !this.tokenMetadata.length) {
        return ''
      }
      return `${this.lotConfig.rewards.amount * amount} ${this.tokenMetadata[0].name}`
    }

    get rewardsAmount () {
      return this.lotConfig?.rewards?.amount
    }


    get daysPeriod () {
      const ONE_DAY = 60 * 60 * 24
      return this.lotConfig.time_per_period / ONE_DAY
    }

    get tcoinRequired () {
      return convertValueFromContract(this.lotConfig.tcoin_required)
    }

    get currentAddress () {
      return this.$store.getters.getAddress
    }

    @Watch('$store.getters.getAddress', {deep: true})
    onChangeAddress (address?: string) {
      if (address) {
        this.getData()
      } else {
        this.countdownDeposit = []
      }
    }

  }
  