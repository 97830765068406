









import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import TrooperzBank from '@/components/Bank/TrooperzBank.vue';
import Comics from '@/components/Comics.vue';


@Component({
  components: {
    TrooperzBank
  }
})
export default class Bank extends Vue {
    get address () {
        return this.$store.getters.getAddress
    } 

    set address (address: string | null) {
        this.$store.commit('setAddress', address)
    }

    changeRoute (routeName: string)  {  
        this.$router.push({ name: routeName })
    }

    routeNameIs (routeName: string) {
        return this.$route.name === routeName
    }
}
